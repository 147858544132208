<template>
    <div>
        <TableList
            :columns="columns"
            :get_table_list="this.get_table_list"
            :slot_table_list="['operation','status']"
            :slot_seo_list="['menu']"
            ref="list"
            @list_after="list_after"
        >
            <template slot="left_btn">
                <a-button class="float_left" v-auth="'change'" @click="add()" type="primary">添加角色</a-button>
            </template>
            <template slot="status" slot-scope="data">
                <status :value="data.text"></status>
            </template>

            <template slot="operation" slot-scope="data">
                <a v-auth="'change'" @click="edit(data.record)">编辑</a>
                <a-divider type="vertical" />

                <a-popconfirm title="您确定要删除吗?" v-auth="'delete'" @confirm="del(data.record)">
                    <a>删除</a>
                </a-popconfirm>
            </template>
        </TableList>
    </div>
</template>

<script>

import { authList, deleteAuth } from '@/api/system'
import TableList from "@/components/TableList";

const columns = [
    {
        title: "角色名称",
        dataIndex: "title",
    },
    {
        title: "角色描述",
        dataIndex: "desc",
    },
    {
        title: "员工数",
        dataIndex: "user_num",
    },
    {
        title: "更新时间",
        dataIndex: "update_time",
    },
    {
        title: "操作",
        width: 150,
        scopedSlots: {
            customRender: "operation"
        }
    },
];


export default {
    name: "Index",
    components: {
        TableList,
    },
    data() {
        return {
            get_table_list: authList,
            columns,
        };
    },
    created() {

    },
    methods: {
        list_after() {
        },
        add() {
            this.$router.push('/auth/index_edit')
        },
        edit(record) {
            this.$router.push({
                path: '/auth/index_edit',
                query: {
                    id: record.id
                }
            })
        },
        del(record) {
            deleteAuth({
                data: {
                    id: record.id
                },
                info: true,
            }).then(res => {
                this.$refs.list.get_list();
            })
        },
        submit(e) {

        },
    }
};
</script>

<style lang="less">
@import url("../../assets/less/app.less");
</style>